<template>
    <main>
        <div class="container">
            <div class="row not-fount-err justify-content-center">
                <div class="col-lg-6 text-center">
                    <div class="sad-face"></div>
                    <h1> 404</h1>
                    <h4> Page not found</h4>
                    <a href="/feed" class="btn">Go back home</a>
                </div>
            </div>
        </div>
    </main>
</template>